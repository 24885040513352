import React, { useState } from 'react'
import { useSelector } from 'react-redux'

// Visual
const Color = require('color')
import { ScrollView, View as NativeView, StatusBar as Bar, SafeAreaView, Switch, TouchableOpacity, Image, KeyboardAvoidingView } from 'react-native'
import { Card as PaperCard, Divider as PaperDivider, TextInput, Appbar, withTheme, ActivityIndicator as PaperActivityIndicator, Title, Text, Button as PaperButton, HelperText as PaperHelperText, Avatar, Subheading as PaperSubheading, Searchbar, Checkbox as PaperCheckbox, IconButton, List, Portal } from 'react-native-paper'
import { Link as NativeLink, withRouter } from '../../../routes/router'
import { isWeb, isIos, isCI } from '../../../modules/apis/platform'

// CI config
const ActivityIndicator = isCI ? ( f => false ) : PaperActivityIndicator

// Actions
import * as Linking from 'expo-linking'

// Optimised react root component
export class Component extends React.Component {

  constructor( props ) {
    super( props )

    // Class-wide functions
    this.promiseState = newState => new Promise( resolve => this.setState( newState, resolve ) )
    this.updateState = updates => this.promiseState( { ...this.state, ...updates } )

  }

}

// ///////////////////////////////
// Recyclable components
// ///////////////////////////////

// Status bar
export const StatusBar = withTheme( ( { theme } ) => <View>
	<Bar backgroundColor={ theme.colors.primary } /> 
</View> )

// Subheading
export const Subheading = ( { style, ...props } ) => <PaperSubheading style={ { marginTop: 20, ...style } } { ...props } />

// Generic card
export const Card = ( { containerStyle, style, children } ) => <View style={ { paddingVertical: 10, width: 500, maxWidth: '100%', ...containerStyle } }>
	<PaperCard elevation={ 2 } style={ { padding: 30, maxWidth: '100%', ...style } }>
		{ children }
	</PaperCard>
</View>

// Modified view with sensible defaults
export const View = ( { style, ...props } ) => <NativeView style={ { maxWidth: '100%', ...style } } { ...props } />

// Divider
export const Divider = ( { style, ...props } ) => <PaperDivider style={ { marginVertical: 20, ...style } } { ...props } />

// Profile image
export const UserAvatar = withRouter( ( { size=100, user, history, ...props } ) => <TouchableOpacity onPress={ f => history.push( `/${user.handle}` ) }>
	{ user.avatar && <Avatar.Image { ...props } size={ size } source={ user.avatar } /> }
	{ !user.avatar && <Avatar.Icon { ...props } size={ size } icon='account-circle-outline' /> }
</TouchableOpacity> )

// Tooltip
export const ToolTip = withTheme( ( { iconSize=30, containerStyle, tooltipStyle, textStyle, label, info, theme, ...props } ) => {

	const [ showInfo, setInfo ] = useState( false )

	return <TouchableOpacity style={ { width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', ...containerStyle } } onPress={ f => setInfo( !showInfo ) } { ...props }>
		<View style={ { flexDirection: 'row', alignItems: 'center', paddingTop: 10, paddingBottom: showInfo ? 0 : 10, ...tooltipStyle } }>
			<Text style={ { ...textStyle } }>{ label }</Text>

			{ /* The info icon */ }
			{ info && <Avatar.Icon style={ { backgroundColor: 'rgba(0,0,0,0)' } } color={ theme.colors.text } size={ iconSize } icon='information-outline' /> }
		</View>

		{ /* the help message triggeres by the info icon */ }
		{ showInfo && info && <PaperHelperText style={ { paddingBottom: 10, textAlign: 'center', ...tooltipStyle } } type={ 'info' }>{ info }</PaperHelperText> }

	</TouchableOpacity>
} )

export const HelperText = ( { icon, ...props } ) => !icon ? <PaperHelperText { ...props } /> : <View style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' } }>
	<PaperHelperText { ...props } />
	<IconButton style={ { opacity: .8, margin: 0 } } icon={ icon } size={ 15 } />
</View>

export const Link = withTheme( ( { style, theme, children, to, onPress, ...props } ) => {

	const text = <Text style={ { color: theme.colors.text, textDecorationLine: 'none', ...theme.fonts.regular, ...style } }>{ children }</Text>
	if( to?.includes( 'http' ) || to?.includes( 'mailto' ) || onPress ) return <TouchableOpacity onPress={ onPress || ( f => Linking.openURL( to ) ) } style={ { borderBottomWidth: .2, paddingTop: 1, marginHorizontal: 5 } }>
		{ text }
	</TouchableOpacity>

	return <TouchableOpacity onPress={ onPress }>
		<NativeLink style={ { textDecorationLine: 'none' } } to={ to }>
			{ text }
		</NativeLink>
	</TouchableOpacity>
} )

// ///////////////////////////////
// Input components
// ///////////////////////////////

// Generic text input
export const Input = ( { style, info, hideInfo=false, error, onSubmit, multiline, editable, copy, copyContent, iconSize=30, value, ref=f=>f, ...props } ) => {

	// Internal variables
	const gutter = multiline ? 200 : undefined
	const [ showInfo, setInfo ] = useState( false )
	const [ height, setHeight ] = useState( gutter )

	// Styles
	const theme = useSelector( store => store?.settings?.theme || {} )
	const inputStyles = { ...( height && { height: height } ), minHeight: 50, marginVertical: 10, backgroundColor: multiline ? theme.colors.background : 'none', ...style }

	// Internal helpers
	const adjustHeight = ( { nativeEvent } ) => {
		if( multiline ) setHeight( nativeEvent?.contentSize?.height + ( isIos ? 35 : 0 ) )
	}
	const manageEnter = ( { nativeEvent } ) => {
		if( nativeEvent.key == 'Enter' ) return onSubmit()
	}


	return <View>
		<View style={ { position: 'relative' } }>

			{ /* The actual input */ }
			<TextInput ref={ ref } onKeyPress={ onSubmit ? manageEnter : f => f } value={ value || '' } onContentSizeChange={ adjustHeight } multiline={ multiline } editable={ editable } mode='flat' dense={ false } { ...props } style={ inputStyles } />

			{ /* The info icon */ }
			{ info && ( !hideInfo || ( hideInfo && !value ) ) && <TouchableOpacity tabindex={ -1 } style={ { position: 'absolute', right: 0, top: 0, bottom: 0, justifyContent: 'center' } } onPress={ f => setInfo( !showInfo ) }>
				<Avatar.Icon style={ { backgroundColor: 'rgba(0,0,0,0)', alignSelf: 'center' } } color={ theme.colors.text } size={ iconSize } icon='information-outline' />
			</TouchableOpacity> }

			{ /* The copy icon */ }
			{ copy  && <TouchableOpacity tabindex={ -1 } style={ { position: 'absolute', right: info ? 30 : 0, top: 0, bottom: 0, justifyContent: 'center' } } onPress={ f => copyContent( value ) }>
				<Avatar.Icon style={ { backgroundColor: 'rgba(0,0,0,0)', alignSelf: 'center' } } color={ theme.colors.text } size={ iconSize } icon='content-copy' />
			</TouchableOpacity> }
		</View>

		{ /* the help message triggeres by the info icon */ }
		{ ( showInfo || error ) && ( info || error ) && <PaperHelperText type={ error ? 'error' : 'info' }>{ error || info }</PaperHelperText> }
	</View>
}

// Button
export const Button = withRouter( withTheme( ( { style, mode='contained', loading=false, children, to, theme, history, onPress, ...props } ) => <View style={ { position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20, ...style } }>
	<PaperButton
		onPress={ to ? f => history.push( to ) : onPress }
		style={ { flexDirection: 'column', alignItems: 'stretch', justifyContent: 'center', width: '100%' } }
		contentStyle={ { width: '100%' } }
		labelStyle={ { color: mode != 'contained' ? theme.colors.text : theme.colors.surface, width: '100%' } }		
		mode={ mode } { ...props }
	>
		{ children }
	</PaperButton>
	{ loading && <ActivityIndicator size={ 15 } color={ mode != 'contained' ? theme.colors.text : theme.colors.background } style={ { height: 20, width: 20 } } /> }
</View>  ) )


// Toggle
export const Toggle = withTheme( ( { style, theme, value, label, onToggle, info, error, ...props } ) => {

	const [ showInfo, setInfo ] = useState( false )

	return <View style={ { flexDirection: 'column', width: '100%' } }>

		{ /* The toggle */ }
		<View style={ { flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'flex-start', ...style } }>
			{ label && <Text onPress={ onToggle } style={ { opacity: .7, marginRight: 20 } }>{ label }</Text> }
			<Switch style={ { marginLeft: 'auto' } } onValueChange={ onToggle } trackColor={ value ? theme.colors.primary : theme.colors.background } thumbColor={ value ? theme.colors.primary : theme.colors.background } value={ value } { ...props } />
			{ info && <TouchableOpacity onPress={ f => setInfo( !showInfo ) }>
				<Avatar.Icon style={ { marginLeft: 10, backgroundColor: 'rgba(0,0,0,0)' } } color={ theme.colors.text } size={24} icon='information-outline' />
			</TouchableOpacity> }
		</View>

		{ /* Info helper message */ }
		{ info && ( showInfo || error ) && <PaperHelperText style={ { paddingLeft: 0, paddingVertical: 20 } } type={ error ? 'error' : 'info' }>{ info }</PaperHelperText> }

	</View>
} )

// Search bar
export const Search = ( { style, searching, ...props } ) => <View>
	<Searchbar { ...props } />
	{ searching && <ActivityIndicator style={ { position: 'absolute', right: 0, height: '100%', paddingHorizontal: 15, backgroundColor: 'white' } } /> }
</View>

// Checkbox
export const Checkbox = ( { checked, children, onPress, style, ...props } ) => <View style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', ...style } }>
	<PaperCheckbox.Android onPress={ onPress } status={ checked ? 'checked' : 'unchecked' } />
	{ children }
</View>

export const Dropdown = withTheme( ( { theme, title='Select', error, label, value, info, items=[], onChange, style, ...props } ) => {

	const [ showInfo, setInfo ] = useState( false )
	const [ open, setOpen ] = useState( false )
	const handleChange = input => {
		onChange( input )
		setOpen( !open )
	}

	return <View style={ { flexDirection: 'column', width: '100%' } }>

		{ /* The toggle */ }
		<View style={ { flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'flex-start', ...style } }>
			
			{ /* Dropdown */ }
			<List.Section style={ { flex: 1 } }>

				<List.Accordion style={ { backgroundColor: value ? theme.colors.background : 'none' } } onPress={ f => setOpen( !open ) } expanded={ open } { ...props } title={ label || value || title }>
					{ items.map( item => {
						if( typeof item == 'string' ) return <List.Item key={ item } style={ { backgroundColor: item == value ? theme.colors.background : 'none' } } title={ item } onPress={ f => handleChange( item ) } />
						if( typeof item == 'object' ) return <List.Item key={ item.id } style={ { backgroundColor: item.value == value ? theme.colors.background : 'none' } } title={ item.title } onPress={ f => handleChange( item.value ) } />
					} ) }
				</List.Accordion>

			</List.Section>

			{ info && <TouchableOpacity onPress={ f => setInfo( !showInfo ) }>
				<Avatar.Icon style={ { marginLeft: 10, backgroundColor: 'rgba(0,0,0,0)' } } color={ theme.colors.text } size={24} icon='information-outline' />
			</TouchableOpacity> }
		</View>

		{ /* Info helper message */ }
		{ info && ( showInfo || error ) && <PaperHelperText style={ { paddingLeft: 0, paddingVertical: 20 } } type={ error ? 'error' : 'info' }>{ info }</PaperHelperText> }

	</View>

} )

// Radio selector
export const Radio = withTheme( ( { theme, title='Select', error, label, value, info, items=[], onChange, style, ...props } ) => {

	const [ showInfo, setInfo ] = useState( false )

	return <View style={ { flexDirection: 'column', width: '100%' } }>

		{ /* The toggle */ }
		<View style={ { flexDirection: 'row', width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start', ...style } }>

			<View style={ { flexDirection: 'column', flex: 1 } }>
				
				{ label && <Text style={ { opacity: .7, marginRight: 20 } }>{ label }</Text> }

				{ /* Selectors */ }
				{ items.map( item => <View key={ item } style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' } }>
					<Checkbox onPress={ f => onChange( item ) } checked={ item == value } />
					<Text style={ { flex: 1 } } onPress={ f => onChange( item ) }>{ item }</Text>
				</View> ) }

			</View>

			{ info && <TouchableOpacity onPress={ f => setInfo( !showInfo ) }>
				<Avatar.Icon style={ { marginLeft: 10, backgroundColor: 'rgba(0,0,0,0)' } } color={ theme.colors.text } size={24} icon='information-outline' />
			</TouchableOpacity> }

		</View>

		{ /* Info helper message */ }
		{ info && ( showInfo || error ) && <PaperHelperText style={ { paddingLeft: 0, paddingVertical: 20 } } type={ error ? 'error' : 'info' }>{ info }</PaperHelperText> }

	</View>

} )

// Radio selector
export const MultiSelect = withTheme( ( { theme, title='Select', error, label, value=[], info, items=[], onChange, style, ...props } ) => {

	const [ showInfo, setInfo ] = useState( false )

	return <View style={ { flexDirection: 'column', width: '100%' } }>

		{ /* The toggle */ }
		<View style={ { flexDirection: 'row', width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start', ...style } }>

			<View style={ { flexDirection: 'column', flex: 1 } }>
				
				{ label && <Text style={ { opacity: .7, marginRight: 20 } }>{ label }</Text> }

				{ /* Selectors */ }
				{ items.map( item => <View key={ item } style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' } }>
					<Checkbox onPress={ f => value?.includes( item ) ? onChange( [ ...value.filter( entry => entry != item ) ] ) : onChange( [ ...value, item ] ) } checked={ value?.includes( item ) } />
					<Text style={ { flex: 1 } } onPress={ f => onChange( item ) }>{ item }</Text>
				</View> ) }

			</View>

			{ info && <TouchableOpacity onPress={ f => setInfo( !showInfo ) }>
				<Avatar.Icon style={ { marginLeft: 10, backgroundColor: 'rgba(0,0,0,0)' } } color={ theme.colors.text } size={24} icon='information-outline' />
			</TouchableOpacity> }

		</View>

		{ /* Info helper message */ }
		{ info && ( showInfo || error ) && <PaperHelperText style={ { paddingLeft: 0, paddingVertical: 20 } } type={ error ? 'error' : 'info' }>{ info }</PaperHelperText> }

	</View>

} )

// Date picker for web only
export const DatePicker = withTheme( ( { theme, style, label, info, onChange, error, helpertext, value, datetime=false, ...props } ) => {

	const [ showInfo, setInfo ] = useState( false )

	if( !isWeb ) return <Text>This date picker only works on web for now.</Text>

	return <View style={ { flexDirection: 'column', width: '100%' } }>

		{ /* The toggle */ }
		<View style={ { flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'flex-start', ...style } }>

			<View style={ { flexDirection: 'column', flex: 1 } }>
				
				{ label && <Text style={ { opacity: .7, marginRight: 20, marginBottom: 10 } }>{ label }</Text> }

				{ /* Selector */ }
				<input onChange={ e => onChange( e?.target?.value ) } { ...props } type={ datetime ? 'datetime-local' : 'date' } value={ value } />

			</View>

			{ info && <TouchableOpacity onPress={ f => setInfo( !showInfo ) }>
				<Avatar.Icon style={ { marginLeft: 10, backgroundColor: 'rgba(0,0,0,0)' } } color={ theme.colors.text } size={24} icon='information-outline' />
			</TouchableOpacity> }

		</View>

		{ /* Info helper message */ }
		{ info && ( showInfo || error ) && <PaperHelperText style={ { paddingLeft: 0, paddingVertical: 20 } } type={ error ? 'error' : 'info' }>{ info }</PaperHelperText> }

	</View>

} )

// ///////////////////////////////
// Screens
// ///////////////////////////////

// Loading screen
export const Loading = ( { message } ) => <Container style={ { justifyContent: 'center' } }>
		<ActivityIndicator size='large' />
		<Title style={ { textAlign: 'center', marginTop: 20 } }>{ message || 'Loading' }</Title>
</Container>

// ///////////////////////////////
// Positioning
// ///////////////////////////////
const sharedStyles = { paddingHorizontal: 10, paddingVertical: 40, maxWidth: '100%', flexGrow: 1, flexShrink: 0 }
export const Main = {
	Center: ( { children, style, containerStyle } ) => ( <ScrollView style={ { maxWidth: '100%', ...containerStyle } } showsHorizontalScrollIndicator={ false } showsVerticalScrollIndicator={ false } contentContainerStyle={ { ...sharedStyles, alignItems: 'center', justifyContent: 'center',  ...style } }>
			{ children }
	</ScrollView> ),
	Top: ( { children, style, containerStyle } ) => ( <ScrollView style={ { maxWidth: '100%', ...containerStyle } } showsHorizontalScrollIndicator={ false } showsVerticalScrollIndicator={ false } contentContainerStyle={ { ...sharedStyles, ...style } }>{ children }</ScrollView> )
}

// General app container
const bgStyles = { position: 'absolute', top: 0, left: 0, bottom: 0, minWidth: '100%', minHeight: '100%' }
export const Container = withTheme( ( { style, children, theme, Background } ) => <KeyboardAvoidingView style={ { flex: 1 } } behavior={ isIos ? 'padding' : 'height' } >
		<SafeAreaView style={ { flex: 1, width: '100%', backgroundColor: theme.colors.primary } }>

		<View style={ {
			flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: theme.colors.background, overflow: 'hidden',
			...style
		} }>
			{ Background && ( isWeb ? <Image style={ bgStyles } source={ Background } /> : <Background height={ '101%' } preserveAspectRatio="xMidYMid slice" style={ bgStyles } /> ) }
			{ Background && <View style={ { position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: Color( theme.colors.background ).alpha( 0.9 ) } } /> }
			{ children }
		</View>
		
	</SafeAreaView>
</KeyboardAvoidingView> )

export const ProfileAvatar = ({ user, size = 30, onPress }) => {

	return <TouchableOpacity onPress={ onPress } >
		{ user.avatar?.uri 
			? <Avatar.Image size={ size } source={ { uri: user.avatar.uri } } onPress={ onPress } />
			: user.name 
				? <Avatar.Text size={ size } label={ getInitials( user.name ) } onPress={ onPress } />
				: <Avatar.Icon size={ size } icon='account' onPress={ onPress } /> 
		}
	</TouchableOpacity>

}

const getInitials = ( name='' ) => {
	const names = name.split(" ")
	if( Array.isArray( names ) ) return `${ names[0] && names[0][0].toUpperCase() }${ names[1] && names[1][0].toUpperCase() }`
	return names[0].toUpperCase()
}

// ///////////////////////////////
// Pass through exports straignt from paper
// ///////////////////////////////
export { Drawer, Provider, FAB, Portal, Appbar, withTheme, Surface, Text, Paragraph, Title, Caption, IconButton, Menu, HelperText as PaperHelperText, List, Searchbar, Avatar } from 'react-native-paper'