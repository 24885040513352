import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useHistory } from '../../../routes/router'

// Visual
import { Component, Container, Loading, Button, Card, Main, Subheading } from '../../stateless/common/generic'
import { ContainerWithNav } from '../../hook/navigation-wrapped'
import { Table } from '../../stateless/account/data-table'
import FAB from '../common/fab'

// Helpers
import { log, catcher, wait, Dialogue, sortFunction, pluralize } from '../../../modules/helpers'

// Data
import app from '../../../modules/firebase/app'
import { useSelector, useDispatch } from 'react-redux'
import { removeEntries } from '../../../redux/actions/entriesActions'


export const DataTable = () => {

	// Redux management
	const itemsPerPage = 10
	const user = useSelector( store => store.user )
	const settings = useSelector( store => store.settings || {} )
	const entries = useSelector( store => store.entries || [] )
	const dispatch = useDispatch()


	// State and param management
	const { typename, typeuid } = useParams()
	const [ loading, setLoading ] = useState(false)
	const [ deletedEntries, setDeletedEntries ] = useState([])
	const [ sortBy, setSortBy ] = useState( {} )
	const [ page, setPage ] = useState( 0 )
	const [ furthestSeenPage, setFurthestSeenPage ] = useState( 0 )
	const [ searchQuery, setSearchQuery ] = useState( "" )

	// First page listener (for newer entries)
	useEffect( f => {
		log( `Load ${ typename } entries in useEffect from scratch` )
		app.loadNextPageOfEntryType( typename )
	}, [ ] )

	// Load next page from remote
	useEffect( f => {
		const [ youngestEntry ] = entries[ typename ] || []
		log( `Load ${ typename } entries in useEffect from `, youngestEntry )
		if( !entries[ typename ] || entries[ typename ]?.length < ( itemsPerPage * 2 ) ) app.loadNextPageOfEntryType( typename, youngestEntry )
	}, [ typename, entries.length ] )

	// If search is activated, load an all-listener
	useEffect( f => {
		if( searchQuery ) app.listenToAllEntriesOfType( typename )
	}, [ typename, searchQuery ] )

	// Pagination control
	const openNextPageAndPreload = next => {

		log( page, next, furthestSeenPage )

		// Update page counters
		setPage( next )
		if( page > furthestSeenPage ) setFurthestSeenPage( page )

		// Load remote if this is the last known page
		if( next <= furthestSeenPage ) return
		const [ youngestEntry ] = entries[ typename ] || []
		log( `Load ${ typename } entries in openNextPageAndPreload from `, youngestEntry )
		return app.loadNextPageOfEntryType( typename, youngestEntry )

	}

	const history = useHistory()

	const deleteEntry = async id => {
		log( 'Deleting entry with id: ', id )
		try {

			await Dialogue( 'Weet je zeker dat je dit wilt verwijderen?', 'Dit kan niet ongedaan worden gemaakt!' )

			// Record the deletion offline instantly
			await setDeletedEntries( [ ...deletedEntries, id ] )

			// Remove deletions from local redux
			dispatch( removeEntries( [ { id: id, type: typename } ] ) )

			await app.deleteEntry( id )

		} catch( e ) {
			log( 'deleteEntry error: ', e )
		}
	}

	const editEntry = id => {
		log( 'Editing entry with id: ', id )
		history.push( `/data/edit/${ typename }/${ id }` )
	}

	const sortTable = async (column, direction= 'ascending', numerical= false) => {
		await app.listenToAllEntriesOfType( typename )
		setSortBy( { column: column, direction: direction, numerical: numerical } )
	}

	// Grab entries of the type we are viewing, filter out the ones we deleted offline and sort according to table view
	const entriesofThisType = entries[ typename ] || []
	const selectedEntries = [ ...entriesofThisType ]
		.filter( entry => !deletedEntries.includes( entry.id ) )
	const type = settings?.datatypes?.find( t => t.name == typename )

	if( !user || loading ) return <Loading message={ loading } />

	return <ContainerWithNav title={ `Bekijk ${  type.labelPlural }` } >
			<Main.Top containerStyle={ { width: '100%' } }>
				<Table 
					deleteEntry={ deleteEntry }
					editEntry={ editEntry }
					sortTable={ sortTable }
					page={ page }
					itemsPerPage={ itemsPerPage }
					onPageChange={ openNextPageAndPreload }
					sortColumn={ sortBy.column }
					sortDirection={ sortBy.direction }
					sortNumerical={ sortBy.numerical }
					sorted={ sortBy }
					type={ type }
					entries={ selectedEntries }
					allEntries={ entries }
					searchQuery={ searchQuery }
					onSearchChange={ query => setSearchQuery( query ) } />
			</Main.Top>
			{/* <FAB go={ to => history.push( to ) } /> */}
		</ContainerWithNav>

}