export const datatypes = {

	// ///////////////////////////////
	// Groepssessie data type
	// ///////////////////////////////
	groepssessie: {

		created: Date.now(),
		creator: 'mentor',

		label: "groepsessie",
		labelPlural: "groepsessies",
		
		fields: [
				{
					uid: `groepssessie-date`,
					name: `Datum en tijd`,
					type: `datetime`,
					values: `\\d\\d\\d\\d-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}T[0-2]{1}[0-9]{1}:[0-5]{1}[0-9]{1}`,
					helpertext: `De extacte tijd en datum van de sessie.`,
					defaultValue: `2021-01-01T13:00`,
					sortable: true,
					searchable: true
				},
				{
					uid: `groepssessie-duration`,
					name: `Duur in minuten`,
					type: `number`,
					values: `[0-9]{1,3}`,
					helpertext: `De duur van de sessie in minuten.`,
					defaultValue: `60`,
					sortable: true,
					numerical: true
				},
				{
					uid: `groepssessie-trainer`,
					name: `Trainer`,
					type: `relation`,
					values: {
						type: `datatype`,
						query: `trainer`,
						labelByFieldUid: `trainer-name`
					},
					helpertext: `Selecteer een trainer voor deze sessie. Trainers moeten eerst aangemaakt worden onder "Trainers".`,
					sortable: true,
					searchable: true
				},
				{
					uid: `groepssessie-company`,
					name: `Bedrijf`,
					type: `relation`,
					values: {
						type: `datatype`,
						query: `company`,
						labelByFieldUid: `company-name`
					},
					helpertext: `Selecteer het bedrijf waarvoor deze groepsessie wordt georganiseerd.`,
					sortable: true,
					searchable: true
				},
				{
					uid: `groepssessie-address`,
					name: `Adres voor navigatie`,
					type: 'text',
					values: '.*',
					helpertext: 'Een Google Maps geschikt adres voor de sessie.',
					hideInTable: true,
					searchable: true
				},
				{
					uid: `groepssessie-address-label`,
					name: `Adres opmerking`,
					type: 'text',
					values: '.*',
					helpertext: 'Een omschrijving om de exacte locatie te vinden. Bijvoorbeeld "bij de grote boom midden in het veld".',
					hideInTable: true,
					searchable: true
				},
				{
					uid: `groepssessie-maximum-capacity`,
					name: `Max. deelnemers`,
					type: 'number',
					values: '\\d*',
					helpertext: 'Het maximaal aantal deelnemers voor deze sessie, moet een getal zijn.',
					hideInTable: true
				}
		],
		permissions: {
			create: [ 'admin' ],
			read: [ 'admin', 'manager', 'employee' ],
			update: [ 'admin', 'manager' ],
			delete: [ 'admin' ]
		} 
	},

	// ///////////////////////////////
	// Trainer data type
	// ///////////////////////////////
	trainer: {

		label: "trainer",
		labelPlural: "trainers",

		fields: [
			{
				uid: `trainer-name`,
				name: `Naam`,
				type: `text`,
				values: '[a-zA-Z\\-\\ ]*',
				helpertext: `Naam van de trainer (letters en - toegestaan`,
				sortable: true,
				searchable: true
			},
			{
				uid: `trainer-title`,
				name: `Titel`,
				type: `text`,
				values: '[a-zA-Z\\,\\ ]*',
				helpertext: `Titel (bijv. certificering niveau) van de trainer. Alleen letters toegestaan.`,
				sortable: true,
				searchable: true
			},
			{
				uid: `trainer-avatar`,
				name: `Avatar`,
				type: `file`,
				values: '.*\\.(jpg|png|jpeg)$',
				helpertext: `Afbeelding van de trianer, .jpg en .png toegestaan.`
			},
			{
				uid: 'invite-link',
				name: 'Uitnodiging link',
				values: [ 'uid', 'trainer-name' ],
				value: 'https://mijn.hardlopenvoorbedrijven.nl/#/login/register/${ uid }/${ trainer-name }', // Where 1 refers to a uid of a field type
				type: 'paragraph',
				helpertext: 'Een link om de trainer zich aan te laten melden op het platform.',
				hideInTable: true,
				readOnly: true,
				copy: true,
				urlEncodeValues: true
			},
			{
				uid: `trainer-active`,
				name: `Geactiveerd`,
				type: `toggle`,
				values: [ true, false ],
				helpertext: `Is deze trainer geactiveerd?`,
				defaultValue: true
			}
		]

	},

	// ///////////////////////////////
	// Company
	// ///////////////////////////////
	company: {

		label: "bedrijf",
		labelPlural: "bedrijven",

		fields: [
			{
				uid: `company-name`,
				name: `Naam`,
				type: `text`,
				values: '.{1,500}',
				helpertext: `Naam van het bedrijf.`,
				sortable: true,
				searchable: true
			},
			{
				uid: `company-code`,
				name: `Uitnodigingscode`,
				type: `text`,
				values: '.{1,500}',
				helpertext: `Uitnodigingscode voor dit bedrijf (dit mag alles zijn, zolang het uniek is).`,
				sortable: true
			},
			{
				uid: `company-status`,
				name: `Geactiveerd`,
				type: `dropdown`,
				values: [ 'enabled', 'disabled' ],
				helpertext: `Heeft dit bedrijf toegang tot de app? Hiermee kan een bedrijf geblokkeerd worden indien nodig.`,
				sortable: true
			}
		]

	},

	// ///////////////////////////////
	// Workouts
	// ///////////////////////////////
	workouts: {

		label: "oefening",
		labelPlural: "oefeningen",

		created: Date.now(),
		creator: 'mentor',
		fields: [
			{
				uid: `workout-title`,
				name: 'Titel',
				type: 'text',
				values: '.*',
				helpertext: 'Titel van de oefening',
				mandatory: true,
				sortable: true,
				searchable: true
			},
			{
				uid: `workout-schema`,
				name: 'Voor schema',
				type: 'relation',
				values: { type: 'datatype', query: 'schema', labelByFieldUid: 'schema-title' },
				helpertext: 'Koppel de oefening aan het juiste schema',
				mandatory: true,
				sortable: true,
				tableGrow: 3,
				searchable: true
			},
			// {
			// 	uid: `workout-goal`,
			// 	name: 'doelen',
			// 	type: 'multiselect',
			// 	values: [ 'basis', 'afstand', 'snelheid', 'conditie' ],
			// 	helpertext: 'Welke doelen zijn relevant voor deze workout?',
			// 	sortable: true
			// },
			{
				uid: `workout-duration`,
				name: `Lengte oefening`,
				type: `number`,
				values: `[0-9]{1,3}`,
				helpertext: `De lengte van deze oefening.`,
				defaultValue: `60`,
				sortable: true,
				numerical: true
			},
			{
				uid: `workout-week`,
				name: 'Week',
				type: 'number',
				values: '[\\d\\.]*',
				helpertext: 'Voor welke week is deze oefening?',
				mandatory: true,
				sortable: true,
				numerical: true
			},
			{
				uid: `workout-week-freq`,
				name: 'Oefningen per week',
				type: 'multiselect',
				values: [ '1', '2' ],
				helpertext: 'Deze oefening is voor mensen die hoeveel oefeningen per week doen?',
				mandatory: true,
				sortable: true,
				numerical: true
			},
			{
				uid: `workout-priority`,
				name: 'Prioriteit',
				type: 'number',
				values: '[\\d\\.]*',
				helpertext: 'Welke prioriteit heeft deze oefening in realtie tot de andere oefeningen?',
				mandatory: true,
				sortable: true
			},
			{
				uid: `workout-distance`,
				name: 'Afstand (km)',
				type: 'multiselect',
				values: [ '3', '5', '10', '15' ],
				helpertext: 'Voor welk afstands doel is deze oefening relevant?',
				sortable: true,
				numerical: true
			},
			{
				uid: `workout-speed`,
				name: 'Hoe snel',
				type: 'longtext',
				values: '[\\s\\S]*',
				helpertext: 'Hoe snel ren je deze oefening.',
				mandatory: true,
				hideInTable: true
			},
			{
				uid: `workout-text`,
				name: 'Omschrijving',
				type: 'longtext',
				values: '[\\s\\S]*',
				helpertext: 'Wat moet de persoon doen?',
				hideInTable: true
			}
		],
			permissions: {
			create: [ 'admin' ],
			read: [ 'admin', 'manager', 'employee' ],
			update: [ 'admin', 'manager' ],
			delete: [ 'admin' ]
		} 

	},

	// ///////////////////////////////
	// Schemas
	// ///////////////////////////////
	schema: {

		label: "schema",
		labelPlural: "schemas",

		created: Date.now(),
		creator: 'mentor',
		
		fields: [

			{
				uid: `schema-title`,
				name: 'Titel schema',
				type: 'text',
				values: '.*',
				helpertext: 'Titel van dit schema',
				mandatory: true,
				sortable: true,
				tableGrow: 3,
				searchable: true
			},
			// {
			// 	uid: `schema-goal`,
			// 	name: 'doelen',
			// 	type: 'multiselect',
			// 	values: [ 'basis', 'afstand', 'snelheid', 'conditie' ],
			// 	helpertext: 'Welke doelen zijn relevant voor dit schema?',
			// 	sortable: true
			// },
			{
				uid: `schema-distance`,
				name: 'Afstand (km)',
				type: 'dropdown',
				values: [ '3', '5', '10', '15' ],
				helpertext: 'Voor welk afstands doel is dit schema relevant?',
				sortable: true,
				numerical: true
			},
			{
				uid: `schema-frequency`,
				name: 'Frequentie',
				type: 'dropdown',
				values: [ '1', '2' ],
				helpertext: 'Voor welke wekelijkse ren frequentie is dit schema relevant',
				sortable: true,
				numerical: true
			},
			{
				uid: `schema-targetgroup`,
				name: 'Voor wie',
				type: 'longtext',
				values: '[\\s\\S]*',
				helpertext: 'Voor wie is dit schema?',
				mandatory: true,
				hideInTable: true
			},
			{
				uid: `schema-opbouw`,
				name: 'Opbouw',
				type: 'longtext',
				values: '[\\s\\S]*',
				helpertext: 'Hoe is de opbouw van dit schema?',
				mandatory: true,
				hideInTable: true
			},
			{
				uid: `schema-uitleg`,
				name: 'Uitleg',
				type: 'longtext',
				values: '[\\s\\S]*',
				helpertext: 'Uitleg van dt schema',
				mandatory: true,
				hideInTable: true
			}

		],
		permissions: {
			create: [ 'admin' ],
			read: [ 'admin', 'manager', 'employee' ],
			update: [ 'admin', 'manager' ],
			delete: [ 'admin' ]
		} 

	}
}

// ///////////////////////////////
// Example data
// ///////////////////////////////
export const example = {
		booking: {
				created: Date.now(),
				creator: 'mentor',
				fields: [				
						{
							uid: 1,
							name: 'name',
							type: 'text',
							values: '.*',
							helpertext: 'All text allowed',
							mandatory: true
						},
						{
							uid: 2,
							name: 'comments',
							type: 'longtext',
							values: '[\\s\\S]*',
							helpertext: 'All text allowed'
						},
						{
							uid: 3,
							name: 'height',
							type: 'number',
							values: '[\\d\\.]*',
							helpertext: 'Height in meters, decimals denominated with a period'
						},
						{
							uid: 4,
							name: 'sex',
							type: 'dropdown',
							values: [ 'male', 'female', 'other' ],
							helpertext: 'Your biological sex'
						},
						{
							uid: 9,
							name: 'related booking',
							type: 'relation',
							values: {
								type: 'datatype',
								query: 'booking',
								labelByFieldUid: 1
							},
							helpertext: 'Select a related booking'
						},
						{
							uid: 5,
							name: 'terms',
							defaultValue: true,
							type: 'toggle',
							values: [ true, false ],
							helpertext: 'Do you accept the terms and conditions'
						},
						{
							uid: 6,
							name: 'awake',
							type: 'radio',
							values: [ 'yes', 'no' ],
							helpertext: 'Are you awake'
						},
						{
							uid: 9966776,
							name: 'multi',
							type: 'multiselect',
							values: [ 'cat', 'nocat' ],
							helpertext: 'How is del gato schrodinger?'
						},
						{
							uid: 7,
							name: 'passport',
							type: 'file',
							values: '.*\\.pdf$',
							helpertext: 'A pdf scan of your passport'
						},
						{
							uid: 8,
							name: 'birthday',
							defaultValue: '2019-09-09',
							type: 'date',
							values: '\\d\\d\\d\\d-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}',
							helpertext: 'Your date of birth formatted at year-month-day'
						},
						{
							uid: 10,
							name: 'appointment',
							defaultValue: '2019-09-09T12:09',
							type: 'datetime',
							values: '\\d\\d\\d\\d-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}T[0-2]{1}[0-9]{1}:[0-5]{1}[0-9]{1}',
							helpertext: 'The date and time of your appointment formatted as year-month-day-time'
						},
						{
							uid: 11,
							name: 'welcomemessage',
							value: 'Welcome friendly ${ 1 }', // Where 1 refers to a uid of a field type
							type: 'paragraph',
							helpertext: 'This is a welcome message that is displayed, you cannot edit it. It can be customized by your in-house wizard.'
						}
				],
				permissions: {
						create: [ 'admin' ],
						read: [ 'admin', 'manager', 'employee' ],
						update: [ 'admin', 'manager' ],
						delete: [ 'admin' ]
				} 
		}
}