import { dataFromSnap } from './helpers'
import { log, getuid } from '../helpers'
import { unregisterListeners } from './_listeners'

export const listenEntries = ( app, dispatch, action ) => {

	const { db, auth } = app
	const { currentUser } = auth
	log( 'Getting data types from backend' )

	// Show all entries, not just owned by user
	if( app?.listeners?.allDataEntries ) return
	app.listeners.allDataEntries = db.collection( 'backendData' ).onSnapshot( async snap => {

		const entries = dataFromSnap( snap, false )

		return dispatch( action( entries ) )

	} )

}

export const loadNextPageOfEntryType = ( app, type, lastOffline, dispatch, action ) => {

	if( app?.listeners?.allDataEntries ) return

	const { db, auth } = app
	const { currentUser } = auth
	const pageSize = lastOffline ? 10 : 50

	log( `Getting next ${ pageSize * 2 } ${ type } from backend starting at `, lastOffline?.created || 'zero' )

	const unpaginaged = db.collection( 'backendData' ).where( 'type', '==', type ).orderBy( 'created', 'desc' )
	const paginated = db.collection( 'backendData' ).where( 'type', '==', type ).orderBy( 'created', 'desc' ).startAt( lastOffline?.created || 0 )
	const query = lastOffline ? paginated : unpaginaged

	// Show all entries, not just owned by user
	const nextPageListener = query.limit( pageSize * 2 ).onSnapshot( async snap => {
		
		const entries = dataFromSnap( snap, false )
		log( `Listener sending to redux: `, entries )

		return dispatch( action( entries ) )

	} )

	// If no pagination listeners at all, create first
	if( !app.listeners.pagination ) return app.listeners.pagination = { [type]: [ nextPageListener ] }

	// If pagination listeners for this type not present, add this type
	if( !app.listeners.pagination[ type ] ) return app.listeners.pagination[ type ] = [ nextPageListener ]

	// If this type exists, add this listener to the list
	app.listeners.pagination[ type ].push( nextPageListener )

}

export const listenToAllEntriesOfType = ( app, type, dispatch, action ) => {

	if( app?.listeners?.allDataEntries ) return

	const { db, auth } = app

	log( `Bulk listener requested for ${ type }` )

	if( app.listeners.pagination && app.listeners.pagination[ type ] ) unregisterListeners( app.listeners.pagination[ type ] )

	// Already listening? Don't do anything
	if( app?.listeners?.bulklisteners && app?.listeners?.bulklisteners[ type ] ) return
	log( `⚠️ Listener created for ${ type }, this is a heavy operation` )
	
	const allDataListener = db.collection( 'backendData' ).where( 'type', '==', type ).onSnapshot( async snap => {

		const entries = dataFromSnap( snap, false )

		return dispatch( action( entries ) )

	} )

	if( !app.listeners.bulklisteners ) return app.listeners.bulklisteners = { [type]: allDataListener }
	app.listeners.bulklisteners[ type ] = allDataListener

}

export const deleteEntry = async ( app, idToDelete ) => app.db.collection( 'backendData' ).doc( idToDelete ).delete()