import React from 'react'
import { Text, Input, Toggle, Dropdown, Radio, MultiSelect, DatePicker, View, Paragraph, ToolTip } from '../common/generic'
import PickFile from '../../stateful/common/file-picker'
import { log, timestampToHuman } from '../../../modules/helpers'

// ///////////////////////////////
// Contron component that splits types
// ///////////////////////////////
export const Field = ( { type, index, entries={}, ...fieldProps } ) => {

	// log( 'Rendering: ', type, fieldProps )

	// Validation
	const error = validationError( fieldProps )
	fieldProps.error = error

	// If the type is relation, then we need to compute the entry list
	if( type == 'relation' ) {

		log( 'Relation fied data: ', fieldProps )

		// Note that relation type so far is only 'dataentry', added for extensibility
		const { type: relationType, query, labelByFieldUid } = fieldProps.values

		// Compute values based on entries
		const entriesOfRightType = entries[ query ]

		// Formulate values based on format needed in dropdown field
		fieldProps.values = entriesOfRightType?.map( entry => {

			// Grab entry elements
			const { id, created, owner, ...fields } = entry

			// Grab the field used for labeling
			const { value: labelFieldValue, definition } = fields[ labelByFieldUid ] || { value: 'No label' }

			return { id: id, title: `${ labelFieldValue } (${ timestampToHuman( created ) })`, value: id }

		} )

		// If the field has a value, make sure to add a friendly label
		if( fieldProps.value ) {
			const entryMatchingValue = entriesOfRightType?.find( entry => entry.id == fieldProps.value ) || {}
			const valueOfLabelField = entryMatchingValue[ labelByFieldUid ]?.value || 'No label'
			fieldProps.label = `${ valueOfLabelField } (${ fieldProps.value })`
		}

	}

	// Render components by type
	return <View style={ { marginVertical: 10, paddingLeft: 15, borderLeftWidth: 1, borderLeftColor: index % 2 == 0 ? 'rgba( 0,0,0,.2 )' : 'rgba( 0,0,0 )' } }>
		{ type == 'text' && <TextField { ...fieldProps } /> }
		{ type == 'longtext' && <LongTextField { ...fieldProps } /> }
		{ type == 'number' && <NumberField { ...fieldProps } /> }
		{ type == 'toggle' && <ToggleField { ...fieldProps } /> }
		{ type == 'dropdown' && <DropdownField { ...fieldProps } /> }
		{ type == 'radio' && <RadioField { ...fieldProps } /> }
		{ type == 'multiselect' && <MultiSelectField { ...fieldProps } /> }
		{ type == 'file' && <FileField { ...fieldProps } /> }
		{ type == 'date' && <DateField { ...fieldProps } /> }
		{ type == 'datetime' && <DatetimeField { ...fieldProps } /> }
		{ type == 'relation' && <DropdownField { ...fieldProps } values={ fieldProps.values } /> }
		{ type == 'paragraph' && <TextParagraph { ...fieldProps } /> }
	</View>

}

// ///////////////////////////////
// Generalised validator
// ///////////////////////////////
export const validationError = ( { value, values, helpertext } ) => {

	// log( `Validating ${ value } against `, Array.isArray( values ) ? values : new RegExp( values ) )

	if( typeof value == 'object' ) log( 'Validating: ', value )

	// If the value is an object  it is a file and the `name` property should be validated against
	if( typeof value == 'object' ) value = value.name

	// If values is an object, then this is a relation which has no validation
	if( typeof values == 'object' ) return false

	// No input? Valid
	if( !value ) return false

	// Array validation? Check if inut is in there
	if( Array.isArray( values ) && !!values?.includes( value ) ) return false

	// Regex string? Validate
	if( typeof values == 'string' ) {
		// If the regex is not an exact match, error
		const [ fullmatch ] = value?.match( new RegExp( values ) ) || []
		log( `Match ${ value } against `, values, ` result: `, fullmatch, ` setting valid to `, fullmatch == value )
		if( fullmatch == value ) return false
	}

	// Validations failed
	if( !helpertext ) log( 'No helper text available for this field: ', value, values, helpertext )
	else return helpertext

}

// ///////////////////////////////
// Input components
// ///////////////////////////////
export const TextParagraph = ( { documentUid, name, value, values, helpertext, allFields=[], allInputs, editable, copy, copyContent, urlEncodeValues } ) => {


	// Check if mandatory uids to substituts are present
	for ( var i = values.length - 1; i >= 0; i-- ) {

		// If this is a request for the document uid, check it it is available
		if( values[i] == 'uid' ) {
			if( documentUid ) continue
			if( !documentUid ) return null
		}

		// If a mandatory field is missing, do not render this component
		if( !allFields.find( ( { uid } ) => values[i] == uid ) ) return null
	}

	let displayString = value

	// For each field, check for a substitution in the paragraph
	if( allFields.length ) allFields.map( field => {
		displayString = displayString.replace( '${' + field.uid + '}', urlEncodeValues ? encodeURIComponent( allInputs[ field.uid ] ) : allInputs[ field.uid ] )
		displayString = displayString.replace( '${ ' + field.uid + ' }', urlEncodeValues ? encodeURIComponent( allInputs[ field.uid ] ) : allInputs[ field.uid ] )
	} )
	if( values.includes( 'uid' ) ) {
		displayString = displayString.replace( '${uid}', documentUid )
		displayString = displayString.replace( '${ uid }', documentUid )
	}

	return <Input multiline={ false } label={ name } value={ displayString } info={ helpertext } editable={ editable } copy={ copy } copyContent={ copyContent } />
}

export const TextField = ( { error, name, onChange, value, helpertext, editable, copy, copyContent } ) => {
	return <Input error={ error } label={ name } onChangeText={ onChange } value={ value } info={ helpertext } editable={ editable } copy={ copy } copyContent={ copyContent } />
}

export const LongTextField = ( { error, name, onChange, value, helpertext } ) => {
	return <Input multiline={ true } error={ error } label={ name } onChangeText={ onChange } value={ value } info={ helpertext } />
}

export const NumberField = ( { error, name, onChange, value, helpertext } ) => {
	return <Input error={ error } label={ name } onChangeText={ onChange } value={ value } info={ helpertext } />
}

export const ToggleField = ( { error, name, onChange, value, helpertext } ) => {
	return <Toggle error={ error } label={ name } onToggle={ onChange } value={ value } info={ helpertext } />
}

export const DropdownField = ( { error, name, onChange, value, label, values, helpertext } ) => {
	return <Dropdown title={ `Select ${ name }` } error={ error } onChange={ onChange } label={ label || name } value={ value } items={ values } info={ helpertext } />
}

export const RadioField = ( { error, name, onChange, value, values, helpertext } ) => {
	return <Radio title={ `Select ${ name }` } error={ error } label={ name } onChange={ onChange } value={ value } items={ values } info={ helpertext } />
}

export const MultiSelectField = ( { error, name, onChange, value, values, helpertext } ) => {
	return <MultiSelect title={ `Select ${ name }` } error={ error } label={ name } onChange={ onChange } value={ value } items={ values } info={ helpertext } />
}

export const FileField = ( { error, name, onChange, value, values, helpertext } ) => {
	return <PickFile label={ `select ${ name }` } error={ error } onSelect={ onChange } value={ value } values={ values } helpertext={ helpertext } />
}

export const DateField = ( { error, name, onChange, value, helpertext } ) => {
	return <DatePicker label={ `Select ${ name }` } name={ name } error={ error } onChange={ onChange } value={ value || `${ new Date().getFullYear() }-01-01` } info={ helpertext } />
}

export const DatetimeField = ( { error, name, onChange, value, helpertext } ) => {
	return <DatePicker datetime={ true } label={ `Select ${ name }` } name={ name } error={ error } onChange={ onChange } value={ value || `${ new Date().getFullYear() }-01-01T00:00` } info={ helpertext } />
}