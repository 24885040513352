import React from 'react'

import { DataTable } from 'react-native-paper';

import { View, Text, Card, Button, Searchbar } from '../common/generic'
import { log, capitalize, sortFunction } from '../../../modules/helpers'
import { useHistory } from '../../../routes/router'


// Sanitise input
const saneDisplayValue = value => {

	// If valule is the value false, display false
	if( value === false ) return 'false'
	// if there is no definition, display '-'
	if( value === undefined || value === null ) return '-'
	// if the value is an arrya, concat
	if( Array.isArray( value ) ) return value.join( ', ' )
	// Otherwise display the value
	else return value

}

const readableDisplayValue = (value, definition, entries) => {

	log( "value check", value, definition ) 

	if( ! definition?.type ) return "-"

	// If its a file return the name and not the url
	if( definition.type == 'file' ) return value.name

	if( definition.type == 'dropdown' && value == 'enabled' ) return '🟢'
	if( definition.type == 'dropdown' && value == 'disabled' ) return '🔴'

	if( definition.type == 'toggle' && value == true ) return '🟢'
	if( definition.type == 'toggle' && value == false ) return '🔴'

	// If not a relation field just return the value
	if( definition.type !== 'relation' ) return value

	// If the type is relation, then we need to compute the label
	const { query, labelByFieldUid } = definition.values
	const entriesOfRightType = entries[ query ]

	// Find the right entry by the value
	const relatedEntry = entriesOfRightType?.find( obj => obj.id === value )

	// Return label by uid
	return relatedEntry ? relatedEntry[labelByFieldUid].value : 'No label found'

}

const searchFilter = (item, query, type, allEntries) => {
	
	if(!query) return true

	// Get the searchable fields from the type
	const searchFields = type.fields.filter( field => field.searchable )

	// Test field value against query
	return searchFields.find( field => {
		if( ! item[ field.uid ]?.value ) return false

		const value = readableDisplayValue( item[ field.uid ].value, item[ field.uid ].definition, allEntries )
		return value.toLowerCase().includes( query.toLowerCase() ) 
	} ) ? true : false

}

export const Table = ( { entries, type, deleteEntry, editEntry, allEntries, sortTable, sorted, page, itemsPerPage, onPageChange, sortColumn, sortDirection, sortNumerical, searchQuery, onSearchChange } ) => {

	const widthPercent = 100
	const visibleFields = type.fields.filter( (field) => field.hideInTable !== true )
	const columnWidth = `${ widthPercent / ( type.fields.length + 1 ) }%`
	const history = useHistory()

	const start = page * itemsPerPage;
	const end = (page + 1) * itemsPerPage;

	// console.log( "loaded entries", entries );

	entries = entries.filter( entry => searchFilter( entry, searchQuery, type, allEntries ) )

	return <Card containerStyle={ { width: `${widthPercent}%` } }>

		<View style={{ width: '100%', flexDirection: 'row' }}>
			<View style={{ flex: 1, justifyContent: 'flex-start', marginBottom: 20 }}>			
				<Searchbar 
					placeholder="Zoeken"
					onChangeText={ onSearchChange }
					value={ searchQuery }
					style={{ maxWidth: 300, height: 34 }}
					inputStyle={{ fontSize: 14 }}/>
			</View>
			<View>
				<Button onPress={ f => history.push( `/data/create/${ type.name }` ) } style={ { marginTop: 0 } }>{ `${ capitalize( type.label ) } toevoegen` }</Button>
			</View>
		</View>

		<DataTable>

		{ /* Heading */ }
		<DataTable.Header>
			{ type.fields.map( ( { name, uid, hideInTable, sortable, tableGrow, numerical } ) => {
				return hideInTable !== true ? <DataTable.Title key={ uid } style={ tableGrow && { flex: tableGrow } } sortDirection={ sortColumn === uid && sortDirection } onPress={ sortable && ( () => sortTable( uid, sortDirection == 'ascending' ? 'descending' : 'ascending', numerical ) ) }>{ capitalize( name ) }</DataTable.Title> : null
			} ) }
			{ /* Edit options */ }
			<DataTable.Title numeric>Bewerk</DataTable.Title>
		</DataTable.Header>

		{ /* Data rows */ }
		{ entries.sort( (a, b) => sortFunction(a, b, sortColumn, sortDirection, sortNumerical ) ).slice( start, end ).map( entry => <DataTable.Row key={ entry.id }>

				{ /* For every entry go through the columns */ }
				{ type.fields.map( column => {

					if( column.hideInTable === true ) return

					// Get the value and definition of this entry
					const { value, definition } = entry[ column.uid ] || { value: undefined, definition: {} }

					// if it is a file show the name and not the url
					const displayValue = readableDisplayValue( value, definition, allEntries )
					return <DataTable.Cell style={ column.tableGrow && { flex: column.tableGrow } }  key={ entry.id + column.uid }>{ saneDisplayValue( displayValue ) }</DataTable.Cell>

				} ) }

				{ /* Edit options */ }
				<DataTable.Cell numeric>
					<Text onPress={ f => editEntry( entry.id ) } style={{marginRight:10}}>✏️</Text>
					<Text onPress={ f => deleteEntry( entry.id ) }>🗑</Text>
				</DataTable.Cell>

				
			</DataTable.Row>
		) }

		<DataTable.Pagination page={page} numberOfPages={Math.ceil(entries.length / itemsPerPage)} onPageChange={page => onPageChange(page)} label={`${start + 1}-${end>entries.length?entries.length:end} of ${entries.length}`} />

		</DataTable>

		{/* <Button onPress={ f => history.push( `/data/create/${ type.name }` ) } style={ { alignSelf: 'center' } }>{ `Create new ${ type.name }` }</Button> */}

	</Card>

}