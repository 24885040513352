import React, { useState } from 'react'

// Visual
import { Menu } from '../stateless/common/navigation'
import { View, Appbar, StatusBar, Container } from '../stateless/common/generic'

// Helpers
import { isWeb, version } from '../../modules/apis/platform'
import { capitalize, pluralize } from '../../modules/helpers'

// Data
import app from '../../modules/firebase/app'
import { useSelector } from 'react-redux'

export const ContainerWithNav = ( { title='', subtitle='', style, links=[], children, ...props } ) => {

	// Menu data
	const datatypes = useSelector( store => store?.settings?.datatypes || [] )

	const [ showMenu, toggleMenu ] = useState( true )

	// Formulate links
	const defaultLinks = [
		{
			label: 'Overzicht',
			to: `/`
		},
		// {
		// 	label: 'Logout',
		// 	onPress: app.logout
		// }
	]

	const dataLinks = datatypes.map( ( { name, labelPlural } ) => ( { label: capitalize( labelPlural ), datatype: name, links: [
		{ label: 'Bekijken', to: `/data/view/${ name }` },
		{ label: 'Aanmaken', to: `/data/create/${ name }` }
	] } ) )

	return <Container style={ { flexDirection: 'row' } }>

		{ /* Menu surface */ }
		{ showMenu && <View style={ { height: '100%' } }>
			<Menu links={ [ ...defaultLinks, ...dataLinks, ...links ] } />
		</View> }

		{ /* Wrapper of content text to menu when opened */ }
		<View style={ { flex: 1, height: '100%', alignItems: 'center', justifyContent: 'center' } }>

			{ /* Appbar wrapper */ }
			<View style={ { width: '100%' } }>
				<StatusBar />
				<Appbar.Header style={ { width: '100%', paddingVertical: 30, ...style } } statusBarHeight={ 0 }>
					<Appbar.Action icon="menu" onPress={ f => toggleMenu( !showMenu ) } />
					<Appbar.Content title={ title } subtitle={ subtitle }/>
				</Appbar.Header>
			</View>

			{ /* Elements to show inside the container */ }
			{ children }

		</View>

	</Container>

}