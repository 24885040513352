import React, { useState, useEffect } from 'react'

// Visual
import { Component, Container, Loading, Button, Card, Main, Subheading, Text, Link } from '../stateless/common/generic'
import { ContainerWithNav } from './navigation-wrapped'
import Navigation from '../stateful/common/navigation'
import FAB from '../stateful/common/fab'

// Helpers
import { log, catcher, wait, pluralize, capitalize } from '../../modules/helpers'

// Data
import app from '../../modules/firebase/app'
import { useSelector } from 'react-redux'
import { useHistory } from '../../routes/router'


export const DataDashboard = () => {


	const user = useSelector( store => store.user )
	const settings = useSelector( store => store.settings )
	const entries = useSelector( store => store.entries )

	const history = useHistory()

	const [ loading, setLoading ] = useState( false )

	// Load the first batch of entries if there are none offline
	useEffect( f => {

		const { datatypes } = settings

		datatypes?.map( ( { name } ) => {

			if( !entries[ name ] ) app.loadNextPageOfEntryType( name )

		} )

	}, [ settings.datatypes ] )
	

	if( !user || loading ) return <Loading message={ loading } />

	return <ContainerWithNav title={ `Overzicht` } >
		<Main.Top style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
			{ settings?.datatypes?.map( ( { name, labelPlural } ) => <Card key={ name } containerStyle={{ width: 200, marginHorizontal: 10 }}>
				<Text style={{ fontSize: 30 }}>{ entries[ name ]?.length }</Text>
				<Text style={{ fontSize: 16, marginBottom: 20 }}>{ capitalize(  labelPlural ) }</Text>
				<Button mode="text" icon="chevron-right" contentStyle={{ flexDirection: 'row-reverse' }} onPress={ f => history.push( `/data/view/${name}` ) }>Beheer</Button>
			</Card> ) }
		</Main.Top>
		<FAB go={ to => history.push( to ) } />
	</ContainerWithNav>

}