import { dataFromSnap } from './helpers'
import { log, getuid } from '../helpers'
import { dev, forceMigrations } from '../apis/platform'

import { datatypes } from './_migrations'

export const listenDataTypes = async ( app, dispatch, action ) => {

	const { db, FieldValue, auth, history } = app
	log( 'Getting data types from backend' )

	try {

		if( forceMigrations ) await createDataTypes( db, false ).then( f => alert( `Ran migrations to backend` ) )
		// Get the data structure document
		const backend = await db.collection( 'backend' ).doc( 'datatypes' ).get( ).then( snap => dataFromSnap( snap ) )
		const types = Object.keys( backend ).filter( item => item != 'id' )
		// Structure & sort by name
		const structuredTypes = types.map( type => ( { ...backend[ type ], name: type } ) ).sort( (a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0) )
		log( 'Data types from remote: ', structuredTypes )

		return dispatch( action( structuredTypes ) )

	} catch( e ) {
		log( 'Error getting backend data: ', e )
		throw 'Error getting backend data'
	}

}

export const saveDataTypeEntry = async ( app, entry ) => {

	const { db, storage, auth } = app
	const { currentUser } = auth

	try {

		const { id: existingId, type, ...fields } = entry
		const saneInput = { type: type }
		log( 'Sanitising ', fields )

		// ///////////////////////////////
		// Validations
		// ///////////////////////////////
		for( let uid in fields ) {

			log( 'Parsing ', uid, ' of ', fields, `which equals: `, fields[uid]?.value, fields[uid]?.definition )
			const { value, definition } = fields[ uid ]

			// Ignore the sorting meta key
			if( uid == 'sortingMeta' ) continue

			// If this is a legacy field ( no definition in remote ) keek it unsanitised
			if( !definition && value ) {
				saneInput[ uid ] = { ...entry[ uid ], definition: {} }
				continue
			}

			// If no values, ignore
			if( !definition && !value ) continue

			// If this field is mandatory and undefined, reject it
			if( !value && definition?.mandatory ) throw `Field ${ definition.name } is mandatory and currently missing.`


			// If it is a file, save it and parse it
			if( definition?.type == 'file' ) {

				// If this is an existing entry, skip the validation if the old string value is used
				if( existingId && value.name && value.url ) {
					saneInput[ uid ] = { ...entry[ uid ] }
					continue
				}

				// Validate file type
				if( value.name.match( new RegExp( definition.values ) )[ 0 ] != value.name ) throw `File ${ value.name } does not match definition: ${ definition.helpertext }.`

				// Get the file extension and upoad the file
				const ext = value.name.match( /(\.)(\w+$)/ )[2]
				const path = `avatars/${ await getuid() }.${ ext }`
				log( 'Saving ', value, ' to ', path )
				const { ref } = await app.storage.child( path ).put( value.file )
				const url = await ref.getDownloadURL()

				// Set the value to include the original name and the eventual url
				saneInput[ uid ] = { value: { name: value.name, url: url }, definition: definition }

				// Skip the below validations for non files
				continue
			}

			//
			// If it is not a file, check if the value matches the definition
			//

			// Array versus string
			if( ( Array.isArray( definition.values ) && typeof value == 'string' ) && !definition.values?.includes( value ) ) throw `Value ${ value } does not match definition: ${ definition.helpertext }.`

			// Array versus array, no validation yet
			// if( ( Array.isArray( definition.values ) && Array.isArray( value ) ) && !definition.values?.includes( value ) )


			// String
			if( typeof values == 'string' ) {
				// If the regex is not an exact match, error
				const [ fullmatch ] = value?.match( new RegExp( definition.values ) ) || []
				if( fullmatch != value ) throw `Value ${ value } does not match definition: ${ definition.helpertext }.`
			}

			// If not errors were thrown for this entry, add it to the sane inputs
			saneInput[ uid ] = { ...entry[ uid ] }

		}

		// Add meta to the entry
		saneInput.owner = currentUser.uid
		saneInput.created = entry.created || Date.now()
		saneInput.updated = Date.now()

		log( 'Sending to remote: ', saneInput )

		if( existingId ) {
			await db.collection( `backendData` ).doc( existingId ).set( saneInput, { merge: true } )
			return existingId
		}
		if( !existingId ) {
			const ref = await db.collection( `backendData` ).add( saneInput )
			return ref.id
		}


		

	} catch( e ) {
		log( 'saveDataTypeEntry error: ', e )
		throw e
	}

}


export const createDataTypes = async ( db, merge=true ) => await db.collection( 'backend' ).doc( 'datatypes' ).set( datatypes, { merge: merge } )