import React from 'react'
import { View } from 'react-native'
import { Card, Input, Button, Divider, Text } from '../common/generic'

export const Login = ( { toggle, proceed, onInput, action='login', name, email, password } ) => <Card>
		{ action == 'register' && <Input value={ name } onChangeText={ t => onInput( 'name', t ) } label='Naam' /> }
		<Input value={ email } onChangeText={ t => onInput( 'email', t ) } label='E-mail' />
		{ action != 'recover' && <Input value={ password } onChangeText={ t => onInput( 'password', t ) } secureTextEntry={ true } label='Wachtwoord' /> }
		<Button onPress={ proceed } icon='login' mode='contained' style={ { marginTop: 20 } }>{ action == 'login' ?  'Inloggen' : action == 'register' ? 'Registreren' : 'Versturen' }</Button>
		<Divider />
		<Button onPress={ f => toggle() } icon={ action == 'login' ? 'account-plus' : 'login' } mode='text' style={ { marginTop: 20 } }>{ action !== 'login' ?  'Inloggen' : 'Registreren' }</Button>
		<Text onPress={ f => toggle( 'recover' ) } style={ { textAlign: 'center', marginTop: 30, opacity: .6 } }>Wachtwoord vergeten?</Text>
</Card>

export const another = true