export const setEntries = entries => ( {
	type: 'SETENTRIES',
	payload: entries
} )

export const appendEntries = entries => ( {
	type: 'APPENDENTRIES',
	payload: entries
} )

export const removeEntries = entries => ( {
	type: 'REMOVEENTRIES',
	payload: entries
} )