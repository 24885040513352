import React, { useState, memo } from 'react'

//Data
import { useSelector, useDispatch } from 'react-redux'
import { toggleDarkMode } from '../../../redux/actions/settingsActions'
import { useHistory } from '../../../routes/router'

// Visual
import { TouchableOpacity, View, Animated, SafeAreaView } from 'react-native'
import { List, Portal, Appbar, Surface, Text, StatusBar, Toggle, ProfileAvatar, Link } from './generic'
import { PanGestureHandler } from 'react-native-gesture-handler'
import { isWeb, version } from '../../../modules/apis/platform'
import { updateIfAvailable } from '../../../modules/apis/updates'

import app from '../../../modules/firebase/app'

// ///////////////////////////////
// Header
// ///////////////////////////////
export const Header = ( { style, back, title, subtitle, toggle, pan, drawer, drawerWidth, drawerTranslate, children, links } ) => <View style={ { width: '100%' } }>
	<StatusBar />
	<Appbar.Header style={ { width: '100%', paddingVertical: 30, ...( !back && { paddingLeft: 20 } ), ...style } } statusBarHeight={ 0 }>
		{ back && <Appbar.BackAction onPress={ back } /> }
		<Appbar.Action icon="menu" onPress={ toggle } />
		<Appbar.Content title={ title } subtitle={ subtitle }/>
		{ children }
		<Appbar.Action icon="menu" onPress={ toggle } />
	</Appbar.Header>
	{ drawer && <Backdrop toggle={ toggle } pan={ pan } width={ drawerWidth }>
 		<Menu links={ links } translate={ drawerTranslate } />
 	</Backdrop> }
</View>

// ///////////////////////////////
// Sidebar
// ///////////////////////////////

// Dark mode
const DarkMode = ( { toggleDark, theme } ) => <View style={ { flexDirection: 'row', paddingHorizontal: 40, paddingVertical: 20, borderTopWidth: 1, borderTopColor: theme.colors.divider } }>
	<Toggle label='Donkere modus' onToggle={ toggleDark } value={ theme.dark } />
</View>

// Backdrop of menu
const Backdrop = ( { children, width, toggle, pan, ...props } ) => <Portal style={ { alignItems: 'center', justifyContent: 'center' } }>

	{  /* Touchable backdrop that closes the sidebar */ }
	<TouchableOpacity activeOpacity={ 1 } onPress={ toggle } style={ { flex: 1 } }>

		{ /* The actual sidebar */ }
		<TouchableOpacity activeOpacity={ 1 } style={ { height: isWeb ? '100vh' : '100%', width: width, maxWidth: '100%', alignSelf: 'flex-start' } }>

			{ /* Animation gesture handler */ }
			<PanGestureHandler onHandlerStateChange={ pan } onGestureEvent={ pan }>

				{ children }

			</PanGestureHandler>

		</TouchableOpacity>

	</TouchableOpacity>

</Portal>

// ///////////////////////////////
//  Menu
// ///////////////////////////////
export const Menu = ( { width, links, go, toggle, pan, translate, toggleDark, ...props } ) => {

	const user = useSelector( store => store.user )
	const theme = useSelector( store => store?.settings?.theme || {} )
	const dispatch = useDispatch()
	const history = useHistory()

	const [ updatesAvailable, setUpdatesAvailable ] = useState( false )
	const [ checkingUpdates, setCheckingUpdates ] = useState( false )
	const [ checkedAt, setCheckedAt ] = useState( undefined )
	const check = async f => {
		setCheckingUpdates( true )
		const available = await updateIfAvailable()
		setUpdatesAvailable( available )
		setCheckingUpdates( false )
		setCheckedAt( `${new Date().getHours()}:${new Date().getMinutes()}` )
	}

	return <Animated.View style={ [ translate, { flex: 1 } ] } key={ history.location.pathname }>

		{ /* Visual surface element */ }
		<Surface style={ { flex: 1, elevation: 5 } }>
			<SafeAreaView style={ { flex: 1, backgroundColor: theme.colors.surface } }>

				{ /* Title */ }
				<View style={ { height: '100%', marginBottom: 0 } }>

					<View style={{ width: '100%', paddingVertical: 30, alignItems: 'center' }}>
						<ProfileAvatar user={ user } size={ 50 } onPress={ () => history.push('/user/settings') } />
						<Text style={{ marginTop: 10, fontSize: 20 }} onPress={ () => history.push('/user/settings') }>{ user.name }</Text>
						<Text onPress={ app.logout }>Uitloggen</Text>
					</View>

					{ /* Elements included from above */ }
					{ links.map( section => section.links ? <SectionAccordeon key={ section.label } section={ section } /> : <SectionItem key={ section.label } section={ section } /> ) }


					<View style={ { marginTop: 'auto', width: '100%' } }>
						{ /* Version info */ }
						{ checkingUpdates && <Text style={ { opacity: .3, padding: 10, textAlign: 'right' } }>Checking for updates</Text> }
						{ !checkingUpdates && !updatesAvailable && <Text onPress={ check } style={ { opacity: .3, padding: 10, textAlign: 'right' } }>{ version } { updatesAvailable ? '- update beschikbaar' : '- laatste versie' } { checkedAt && `(gecontroleerd: ${ checkedAt })` }</Text> }
						{ /* Darkmode toggle */ }
						<DarkMode toggleDark={ f => dispatch( toggleDarkMode() ) } theme={ theme } />
					</View>

				</View>
			</SafeAreaView>
		</Surface>

	</Animated.View>
}

const SectionAccordeon = memo( ( { section, ...props } ) => {

	const theme = useSelector( store => store?.settings?.theme || {} )
	const history = useHistory()
	const [ isExpanded, setExpanded ] = useState( history?.location?.pathname.split("/").includes( section.datatype ) )

	return <List.Accordion { ...props } expanded={ isExpanded } onPress={ f => setExpanded( !isExpanded ) } style={ { } } title={ section.label }>
		{ section.links.map( ( { label, to, onPress } ) => <List.Item 
				style={ {
					paddingHorizontal: 20, paddingVertical: 10, fontSize: 13,
					backgroundColor: history?.location?.pathname == `/data/${ label.toLowerCase() }/${ section.datatype }` ? theme?.colors?.background : undefined
				} }
				active={ history?.location?.pathname == to }
				key={ label+to }
				description={ label }
				onPress={ onPress ? onPress : f => history.push( to ) }
		/> ) }
	</List.Accordion>
} ) 

const SectionItem = ({ section }) => {

	const { label, to, onPress } = section

	const theme = useSelector( store => store?.settings?.theme || {} )
	const history = useHistory()

	return <List.Item 
		title={ label }
		key={ label }
		onPress={ onPress ? onPress : f => history.push( to ) }	
	/>

}
