import React from 'react'
import { connect } from 'react-redux'

// File specific
import * as DocumentPicker from 'expo-document-picker'

// Visual
import { TouchableOpacity } from 'react-native'
import { Component, Avatar, HelperText, View, Text, PaperHelperText } from '../../stateless/common/generic'

// Helpers
import { log } from '../../../modules/helpers'
import { validationError } from '../../stateless/account/data-create'

class PickFile extends Component {

	state = {
		file: undefined
	}


	pickFile =  async f => {

		const { onSelect } = this.props
		
		try {
			const file = await DocumentPicker.getDocumentAsync()
			onSelect( file )


		} catch( e ) {
			alert( e )
		}
		

	}

	toggleInfo = f => this.updateState( { showInfo: !this.state.showInfo } )

	render( ) {

		const { showInfo, internalError } = this.state
		const { style, theme, label, value, onSelect, helpertext, error } = this.props
		const visualError = internalError || error

		log( 'File picker: ', this )

		return <View>
			{ label && <Text style={ { opacity: .7, marginRight: 20, marginBottom: 5 } }>{ label }</Text> }
			<TouchableOpacity onPress={ this.pickFile } style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', ...style } }>

				<Avatar.Icon style={ { background: 'none' } } size={ 20 } icon='file' />
				<HelperText style={ { flex: 1 } }>{ value?.name || 'klik om een bestand te selecteren' }</HelperText>
				
				{ helpertext && <TouchableOpacity onPress={ this.toggleInfo }>
					<Avatar.Icon style={ { marginLeft: 10, backgroundColor: 'rgba(0,0,0,0)' } } color={ theme.colors.text } size={24} icon='information-outline' />
				</TouchableOpacity> }

			</TouchableOpacity>

			{ /* Info helper message */ }
			{ helpertext && ( showInfo || visualError ) && <PaperHelperText style={ { paddingLeft: 0, paddingVertical: 20 } } type={ visualError ? 'error' : 'info' }>{ helpertext }</PaperHelperText> }


			
		</View>

		

	}



}

export default connect( store => ( {
	theme: store.settings.theme
} ) )( PickFile )