import { log } from '../../modules/helpers'

const sortEntriesByType = ( unsortedEntries=[] ) => {

	let sortedEntries = {}
	// Loop over the entries and sort them by type
	for ( let i = unsortedEntries.length - 1; i >= 0; i-- ) {
		const { type, ...entry } = unsortedEntries[i]

		if( !type ) {
			log( 'typeless entry: ', unsortedEntries[i] )
			continue
		}

		// If type array exists dd to it
		if( sortedEntries[ type ]?.length ) sortedEntries[ type ].push( entry )

		// Otherwise make the type array
		else sortedEntries[ type ] = [ entry ]
	}

	// Sort entries internally by date
	sortedEntries = Object.keys( sortedEntries ).reduce( ( acc, key ) => {
		const sorted = sortedEntries[ key ].sort( ( a, b ) => {
			if( a.created > b.created ) return 1
			if( a.created < b.created ) return -1
			else return 0
		} )
		acc[ key ] = sorted
		return acc
	}, {  } )

	return sortedEntries

}

const dedupeEntries = ( oldEntries=[], newEntries=[] ) => {
	const deduped = [ ...newEntries ]
	for (var i = oldEntries.length - 1; i >= 0; i--) {
		if( !deduped.find( ( { id } ) => oldEntries[i].id == id ) ) deduped.push( oldEntries[i] )
	}
	return deduped
}

const arrayFromEntryState = ( state={} ) => {
	const types = Object.keys( state )
	const entries = []
	for (var i = types.length - 1; i >= 0; i--) {
		if( !Array.isArray( state[ types[i] ] ) ) continue
		else {
			const entriesWithType = state[ types[i] ].map( entry => ( { ...entry, type: types[ i ] } ) )
			entries.push( ...entriesWithType )
		}
	}
	return entries
}

export default ( state={}, action ) => {

	const { type, payload } = action
	

	switch( type ) {

		case "SETENTRIES":
			
			return sortEntriesByType( payload )
		
		case "APPENDENTRIES":

			const oldEntries = arrayFromEntryState( state )
			const dedupedEntries = dedupeEntries( oldEntries, payload )

			return sortEntriesByType( dedupedEntries )

		case "REMOVEENTRIES":

			// Create new state to manipulate
			const newState = { ...state }

			// For every entry deletion, remove an entry
			payload.map( ( { id, type } ) => {

				// Grab relevant subsection and rempve offending ID
				const subSection = [ ...newState[ type ] ].filter( entry => entry.id != id )

				// Set updated subsection to new state
				newState[ type ] = subSection

			} )

			return newState

		// Just return the state if no known action is specified
		default:
			return state
	}
}