import React from 'react'

// Visual
import { Component, Container, Loading, Main, Card, Subheading, Button } from '../../stateless/common/generic'
import { Field } from '../../stateless/account/data-create'
import { ContainerWithNav } from '../../hook/navigation-wrapped'
import FAB from '../common/fab'

// Helpers
import { log, catcher, capitalize } from '../../../modules/helpers'

// Data
import app from '../../../modules/firebase/app'
import { connect } from 'react-redux'
import { setEntries, appendEntries } from '../../../redux/actions/entriesActions'


class DataCreate extends Component {

	state = { action: 'Create', loading: false, input: {}, type: undefined, label: undefined,fields: [] }

	componentDidMount = f => {

		this.updateForm();

	}

	updateForm = () => {

		const { match, settings, entries } = this.props 
		const { entryid, createtype, edittype } = match.params
		
		// if type is defined, this is a new entry
		if( createtype ) {
			const fields = [ ...settings?.datatypes?.find( t => t.name == createtype ).fields ]
			return this.updateState( {
				action: 'Create',
				type: createtype,
				label: settings?.datatypes?.find( t => t.name == createtype )?.label,
				fields: fields,
				// Add default values to the internal input state
				input: fields.reduce( ( defaultValues, field ) => field.defaultValue ? ( { ...defaultValues, [field.uid]: field.defaultValue } ) : defaultValues, {} )
			} )
		}

		// If the entry id was defined, this is an edit
		if( entryid ) {

			const { created, id, owner, ...entryContent } = entries[ edittype ].find( e => e.id == entryid )
			log( 'Raw entry content: ', entryContent )
			const saneEntryContent = Object.keys( entryContent ).reduce( ( saneObject, fieldUid ) => {
				return { ...saneObject, [ fieldUid ]: entryContent[ fieldUid ].value }
			}, {} )
			log( 'Sane entry content: ', saneEntryContent )

			return this.updateState( {
				action: 'Edit',
				type: edittype,
				label: settings?.datatypes?.find( t => t.name == edittype )?.label,
				fields: [ ...settings?.datatypes?.find( t => t.name == edittype ).fields ],
				input: { ...saneEntryContent },
				editEntryId: id
			} )
		}

	}

	componentDidUpdate = ( prevProps ) => {

		const { match } = this.props 
		if( prevProps.match.url !== match.url ) this.updateForm()

	}

	onInput = ( key, value ) => {

		const { input } = this.state

		log( `Set ${ key } to ${ value }` )
		return this.updateState( {
			input: { ...input, [key]: value }
		} )

	}


	copyContent = ( content ) => {
		
		navigator.clipboard.writeText( content )

	}

	saveEntry = async e => {

		const { dispatch } = this.props

		try {
			const { editEntryId, input, type, fields } = this.state
			const { settings, history } = this.props

			// Check if all mandatory fields are filled in
			for( let field of fields ) {
				log( field )
				if( field.mandatory && !input[ field.uid ] ) throw `${ field.name } is een verplicht veld.`
			}

			// Add the definition state of the entry to the entry at time of saving
			const annotatedInput = {}
			for( let uid in input ) {
				annotatedInput[ uid ] = { value: input[ uid ], definition: fields.find( field => field.uid == uid ) }
			}

			// Add the entry type to the entry
			annotatedInput.type = type

			// Save to firebase
			log( 'Saving: ', annotatedInput )
			await this.updateState( { loading: `${ type } aan het opslaan` } )
			
			// If this is an edit, add the id
			if( editEntryId ) annotatedInput.id = editEntryId

			const entryId = await app.saveDataTypeEntry( annotatedInput )
			dispatch( appendEntries( [ { ...annotatedInput, id: entryId } ] ) )

			if( !editEntryId ) history.push( `/data/view/${ type }` )
			if( editEntryId )  history.push( `/data/view/${ type }` )
			
		} catch( e ) {
			log( 'saveEntry error: ', e )
			alert( `Fout bij het opslaan: ${ e }`)
			await this.updateState( { loading: false } )
		}

	}


	render() {

		const { loading, input, label, fields, action, editEntryId } = this.state
		const { user, history, entries, match, settings } = this.props
		log( this.state, entries )
		log( "fields", fields )


		if( !user || loading ) return <Loading message={ loading } />

		return <ContainerWithNav title={ `${ capitalize( label ) } aanmaken` }>
			<Main.Top>

				<Card>

					<Subheading style={ { marginBottom: 20 } }>{ action === 'Edit' ? `Bewerk` : `Nieuw` } { label }</Subheading> 
					
					{ fields.map( ( field, i ) => ( action == 'Edit' || !field.readOnly ) && <Field documentUid={ editEntryId } allFields={ fields } allInputs={ input } entries={ entries } editable={ !field.readOnly } copy={ field.copy } copyContent={ this.copyContent } urlEncodeValues={ field.urlEncodeValues } index={ i } onChange={ input => this.onInput( field.uid, input ) } key={ field.uid } value={ input[ field.uid ] } { ...field } /> ) }

					<Button onPress={ this.saveEntry }>Bewaar { label }</Button>

				</Card>
				

			</Main.Top>
			
			{/* <FAB go={ to => history.push( to ) } /> */}
		</ContainerWithNav>

	}

}

export default connect( store => ( {
	user: store.user,
	settings: store.settings,
	entries: store.entries
} ) )( DataCreate )